import '~/javascript/_jquery'

$(function() {
  window.Accessibility = window.Accessibility || {}
  window.Accessibility.Accordion = window.Accessibility.Accordion || {}

  window.Accessibility.Accordion = function() {
    const expandedSections = []

    const services = {
      init
    }

    /**
     * Initialises all methods. This is publicly accessible
     * @return {[type]} [description]
     */
    function init() {
      cleanupAccordion()
      storeAccordionState()
      expandCollapseSections()
    };

    /**
     * Removes/updates accordion roles based on Natlib standards
     * @return {null}
     */
    function cleanupAccordion() {
      $('ul.accordion').removeAttr('role')
      $('div.accordion-content').removeAttr('role')
      $('a.accordion-title').attr('role', 'button')
    };

    /**
     * Determines if we need to open the accordion
     * @return {null}
     */
    function storeAccordionState() {
      // Make sure storage is enabled
      if (typeof (Storage) === 'undefined') {
        return
      }

      // Determine if we have session storage saved
      $('.accordion-content').each(function(i, obj) {
        const target = $(obj)
        const identifier = target.data('accordionId')

        if (identifier && sessionStorage.getItem(identifier)) {
          $('.accordion').foundation('down', target)
        }
      })

      // Listen to Accordion events
      $('.accordion').on('down.zf.accordion', function(e, panel) {
        expandedSections.push(panel.attr('id'))
        setAccordionSessionStorage(panel, true)
        setToggleSectionToggler(panel)
      })

      $('.accordion').on('up.zf.accordion', function(e, panel) {
        expandedSections.pop(panel.attr('id'))
        setAccordionSessionStorage(panel, false)
        setToggleSectionToggler(panel)
      })
    };

    /**
     * Store the state in Session storage
     * @param {Object} panel The accordion content panel
     * @param {Boolean} state true or false value to add/remove the state from Storage
     */
    function setAccordionSessionStorage(panel, state) {
      const identifier = $(panel).data('accordionId')

      if (identifier) {
        if (state) {
          sessionStorage.setItem(identifier, 'open')
        } else {
          sessionStorage.removeItem(identifier)
        }
      }
    }

    function expandCollapseSections() {
      const accordionToggles = document.querySelectorAll('.contentful-accordion__toggle')

      accordionToggles.forEach(function(accordionToggle) {
        const targetId = accordionToggle.dataset.target
        const targetAccordion = document.getElementById(targetId)
        const accordionContents = targetAccordion.querySelectorAll('.accordion-content')
        const accordionContentIds = Array.from(accordionContents).map(function(accordionContent) {
          return accordionContent.id
        })

        accordionToggle.addEventListener('click', function(e) {
          e.preventDefault()

          if (e.target.dataset.expanded === 'true') {
            toggleSectionToggler(false, e.target)

            accordionContentIds.forEach(function(accordionContentId) {
              $('.accordion').foundation('up', $('#' + accordionContentId))
            })
          } else {
            toggleSectionToggler(true, e.target)

            accordionContentIds.forEach(function(accordionContentId) {
              $('.accordion').foundation('down', $('#' + accordionContentId))
            })
          }
        })
      })
    }

    function setToggleSectionToggler(panel) {
      const accordion = panel.closest('.accordion')
      const accordionContents = accordion.find('.accordion-content')
      const expandedAccordionContents = accordion.find('.accordion-content:visible')

      let togglerTop
      let togglerBottom
      try {
        togglerTop = accordion[0].previousElementSibling.querySelector('a')
        togglerBottom = accordion[0].nextElementSibling.querySelector('a')
      } catch (e) {

      }

      if (accordionContents.length === expandedAccordionContents.length) {
        if (togglerTop) {
          toggleSectionToggler(true, togglerTop)
        }

        if (togglerBottom) {
          toggleSectionToggler(true, togglerBottom)
          togglerBottom.classList.remove('hide')
        }
      } else {
        if (togglerTop) {
          toggleSectionToggler(false, togglerTop)
        }

        if (togglerBottom) {
          toggleSectionToggler(false, togglerBottom)
          togglerBottom.classList.add('hide')
        }
      }
    }

    // true toggle value means expand
    function toggleSectionToggler(toggle, toggler) {
      if (toggler) {
        if (toggle) {
          toggler.dataset.expanded = 'true'
          toggler.text = 'Hide all sections'
        } else {
          toggler.dataset.expanded = 'false'
          toggler.text = 'Show all sections'
        }
      }
    }

    return services
  }

  // Intialize A11Y for Accordion
  const a11yAccordion = new window.Accessibility.Accordion()
  a11yAccordion.init()
})