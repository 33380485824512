// Entry point for the build script in your package.json
import '~/javascript/consyncful/accordion'
import '~/javascript/_jquery'
import '~/javascript/navigation'
import '~/javascript/social-media-share'

// Load and init foundation JS, note this needs to be require, not an import
import 'foundation-sites'

document.addEventListener('DOMContentLoaded', () => $(document).foundation())

