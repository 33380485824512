// Code for the menu/burger button
document.addEventListener('DOMContentLoaded', function () {
  const menuButton = document.getElementById('menu_button')

  if (menuButton) {
    menuButton.addEventListener('click', () => {
      const menu = document.getElementById('menu')

      if (menuButton.getAttribute('aria-expanded') === 'false') {
        menuButton.setAttribute('aria-expanded', 'true')
        menuButton.setAttribute('aria-label', 'Close main menu')
        menuButton.setAttribute('title', 'Close main menu')
        menu.classList.add('open')
      } else {
        menuButton.setAttribute('aria-expanded', 'false')
        menuButton.setAttribute('aria-label', 'Open main menu')
        menuButton.setAttribute('title', 'Open main menu')
        menu.classList.remove('open')
      }
    })
  }
})